import Script from 'next/script';

const GrAutomationScript = () => {
    return (
        <Script id="gr-automation-script">
            {`function get_gr_automation_user_id() {
                try  {
                    if (typeof localStorage !== 'undefined') {
                        return localStorage.getItem("_gr_email_visitor");
                    }
                } catch(e) {
                    return false;
                }
                return false;
            }`}
        </Script>
    );
};

export default GrAutomationScript;
