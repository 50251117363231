// @ts-ignore
import cookie from 'cookie';

import { NextResponse } from 'next/server';
import { ServerResponse } from 'http';

class AdwordsCookie {
    content: string;

    constructor(content: string) {
        this.content = content;
    }

    getParam(name: string): string | null {
        return JSON.parse(this.content)[name];
    }

    setCookie(response: NextResponse | ServerResponse) {
        let cookieData = cookie.serialize('adwords', this.content, {
            httpOnly: true,
            secure: process.env.NODE_ENV !== 'development',
            maxAge: 60 * 60 * 24 * 365,
            path: '/',
            sameSite: 'none',
            domain: '.getresponse.com',
        });
        if (response instanceof NextResponse) {
            response.headers.set('Set-Cookie', cookieData);
        } else {
            response.setHeader('Set-Cookie', cookieData);
        }
    }
}

export const getAdwordsCookie = (params: any): AdwordsCookie | null => {
    const paramLengthLimits: any = {
        serv: 64,
        type: 255,
        camp: 255,
        kw: 255,
        kww: 255,
        crtn: 255,
        ref: 255,
        medium: 255,
    };

    let adwordParams: any = {
        serv: params['serv'] ?? params['utm_source'],
        type: params['type'],
        camp: params['camp'] ?? params['utm_campaign'],
        kw: params['kw'] ?? params['utm_term'],
        kww: params['kww'],
        crtn: params['crtn'] ?? params['utm_content'],
        ref: params['ref'],
        sv: params['sv'],
        medium: params['medium'] ?? params['utm_medium'],
    };

    const isValidParam = (param: string) => {
        if (typeof adwordParams[param] !== 'string') {
            return false;
        }
        if (param === 'sv' && ['ordernow', 'signupfree', 'both'].includes(adwordParams[param])) {
            return true;
        }
        return param !== 'sv' && !!adwordParams[param];
    };

    adwordParams = Object.keys(adwordParams).reduce((obj: any, param) => {
        if (!isValidParam(param)) {
            delete obj[param];
            return obj;
        }
        obj[param] = adwordParams[param].substring(0, paramLengthLimits[param]);
        return obj;
    }, {});

    if (!adwordParams['serv'] || !adwordParams['camp']) {
        return null;
    }

    return new AdwordsCookie(JSON.stringify(adwordParams));
};
