export const laravelRouteAboutInvestorRelations = (locale) => {
    const alternates = {
        pl: 'https://www.getresponse.pl/email-marketing/o-getresponse/relacje-inwestorskie',
        en: 'https://www.getresponse.com/about/investor-relations',
    };
    return alternates[locale] ?? alternates['en'];
};

export const multisiteRouteLegalPagePrivacyUsMaxPrivacyPolicyUs = (locale) => {
    const alternates = {
        pt: 'https://www.getresponse.com/pt/legal/privacy-us/max-privacy-policy-us',
        en: 'https://www.getresponse.com/legal/privacy-us/max-privacy-policy-us',
    };
    return alternates[locale] ?? alternates['en'];
};

export const multisiteRouteLegalPagePrivacyMaxPrivacyPolicy = (locale) => {
    const alternates = {
        es: 'https://www.getresponse.com/es/legal/privacy/max-privacy-policy',
        fr: 'https://www.getresponse.com/fr/legal/privacy/max-privacy-policy',
        it: 'https://www.getresponse.com/it/legal/privacy/max-privacy-policy',
        vn: 'https://www.getresponse.com/vn/legal/privacy/max-privacy-policy',
        id: 'https://www.getresponse.com/id/legal/privacy/max-privacy-policy',
        nl: 'https://www.getresponse.com/nl/legal/privacy/max-privacy-policy',
        en: 'https://www.getresponse.com/legal/privacy/max-privacy-policy',
    };
    return alternates[locale] ?? alternates['en'];
};

export const multisiteRouteLegalPageMaxAntispamPolicy = (locale) => {
    const alternates = {
        de: 'https://www.getresponse.com/de/legal/max-antispam-policy',
        es: 'https://www.getresponse.com/es/legal/max-antispam-policy',
        fr: 'https://www.getresponse.com/fr/legal/max-antispam-policy',
        it: 'https://www.getresponse.com/it/legal/max-antispam-policy',
        vn: 'https://www.getresponse.com/vn/legal/max-antispam-policy',
        id: 'https://www.getresponse.com/id/legal/max-antispam-policy',
        nl: 'https://www.getresponse.com/nl/legal/max-antispam-policy',
        en: 'https://www.getresponse.com/legal/max-antispam-policy',
    };
    return alternates[locale] ?? alternates['en'];
};

export const multisiteRouteLegalPageMaxTermsOfService = (locale) => {
    const alternates = {
        de: 'https://www.getresponse.com/de/legal/max-terms-of-service',
        es: 'https://www.getresponse.com/es/legal/max-terms-of-service',
        fr: 'https://www.getresponse.com/fr/legal/max-terms-of-service',
        it: 'https://www.getresponse.com/it/legal/max-terms-of-service',
        vn: 'https://www.getresponse.com/vn/legal/max-terms-of-service',
        id: 'https://www.getresponse.com/id/legal/max-terms-of-service',
        nl: 'https://www.getresponse.com/nl/legal/max-terms-of-service',
        en: 'https://www.getresponse.com/legal/max-terms-of-service',
    };
    return alternates[locale] ?? alternates['en'];
};
