import { useEffect } from 'react';

export const useForceHtmlLangAttribute = (desiredLang) => {
    useEffect(() => {
        if (desiredLang === 'vi') {
            document.documentElement.lang = desiredLang;

            const langObserver = new MutationObserver(() => {
                if (document.documentElement.lang !== desiredLang) {
                    document.documentElement.lang = desiredLang;
                }
            });
            langObserver.observe(document.documentElement, {
                attributeFilter: ['lang'],
            });

            return () => {
                langObserver.disconnect();
            };
        }
    }, [desiredLang]);
};
