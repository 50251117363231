import TransFromNextTranslate from 'next-translate/Trans';

import { usePreviewContext } from '@context/PreviewContext';

import useTranslation from '@hooks/useTranslation';

export default function Trans(props) {
    const previewContext = usePreviewContext();
    if (typeof previewContext === 'undefined' || previewContext.isPreview === false) {
        return <TransFromNextTranslate {...props} />;
    }
    const { t } = useTranslation(props.ns);
    return (
        <TransFromNextTranslate
            {...props}
            i18nKey="__non_existent"
            fallback={t(props.i18nKey)}
        />
    );
}
