import { useRouter } from 'next/router';

export const useDomains = () => {
    const { locale, locales } = useRouter();

    const domains = locales.map((locale) => {
        if (locale == 'pl') {
            return {
                locale: 'pl',
                domain: `https://${process.env.NEXT_PUBLIC_HOST_EN === process.env.NEXT_PUBLIC_HOST_PL ? process.env.NEXT_PUBLIC_HOST_PL + '/pl' : process.env.NEXT_PUBLIC_HOST_PL}`,
            };
        } else if (locale == 'en') {
            return {
                locale: 'en',
                domain: `https://${process.env.NEXT_PUBLIC_HOST_EN}`,
            };
        } else {
            return {
                locale: locale,
                domain: `https://${process.env.NEXT_PUBLIC_HOST_EN}/${locale}`,
            };
        }
    });

    const domain = domains.find((d) => d.locale === locale).domain;

    return {
        domains,
        domain,
    };
};
