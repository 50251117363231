import getConfig from 'next/config';

import PropTypes from 'prop-types';

import styles from './ItemRecentPosts.module.css';

const { publicRuntimeConfig } = getConfig();

const ItemRecentPosts = ({ image, title, url, darkMode = false }) => {
    const item = (
        <li className={darkMode ? styles.darkMode : ''}>
            <a
                href={url}
                data-test="footerBlogPost"
                className={styles['single-post']}
            >
                <div className={styles['image']}>
                    <img
                        loading="lazy"
                        width="102"
                        height="68"
                        src={`${publicRuntimeConfig.resizer}102x68/${image}`}
                        srcSet={`${publicRuntimeConfig.resizer}102x68/${image} 1x, ${publicRuntimeConfig.resizer}204x136/${image} 2x`}
                        alt={title}
                    />
                </div>
                <div className={styles['meta']}>
                    <p dangerouslySetInnerHTML={{ __html: title }} />
                </div>
            </a>
        </li>
    );

    return item;
};

ItemRecentPosts.propTypes = {
    darkMode: PropTypes.bool,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default ItemRecentPosts;
