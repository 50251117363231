import { createContext, useContext, useEffect, useState } from 'react';

const PreviewContext = createContext({ isPreview: false });

const PROD_TRANSLATION_VERSION_NAME = 'prod';

export function PreviewWrapper({ children }) {
    const [isPreview, setIsPreview] = useState(false);
    const [translationKeysDetailsVisible, setTranslationKeysDetailsVisible] = useState(false);
    const [translationKeysLanguageOverride, setTranslationKeysLanguageOverride] = useState(false);
    const [geoOverride, setGeoOverride] = useState();
    const [translationVersionName, setTranslationVersionName] = useState(PROD_TRANSLATION_VERSION_NAME);
    const refresh = () => {
        fetch('/api/previewData')
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return response.json().then((data) => {
                    throw new Error(data.error);
                });
            })
            .then((data) => {
                setIsPreview(true);
                setTranslationKeysDetailsVisible(data.keysDetailsVisible);
                setTranslationKeysLanguageOverride(data.languageOverride);
                setGeoOverride(data.geoOverride);
                setTranslationVersionName(data.translationVersionName ?? PROD_TRANSLATION_VERSION_NAME);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        refresh();
    }, []);

    const setVersionName = async (translationVersionName) => {
        return fetch('/api/preview/addData', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ translationVersionName: translationVersionName }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    refresh();
                }
            });
    };
    const setLanguageOverride = async (lang) => {
        return fetch('/api/preview/addData', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ languageOverride: lang }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    refresh();
                }
            });
    };
    const setGeolocationOverride = async (location) => {
        return fetch('/api/preview/addData', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ geoOverride: location }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    refresh();
                }
            });
    };
    const setDetailsVisible = (visible) => {
        fetch('/api/preview/addData', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ keysDetailsVisible: visible }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    refresh();
                }
            });
    };
    const clearData = async () => {
        return await fetch('/api/preview/addData', {
            method: 'delete',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    refresh();
                }
            });
    };

    let sharedState = {
        isPreview,
        translationVersionName,
        translationKeysLanguageOverride,
        translationKeysDetailsVisible,
        geoOverride,
        refresh,
        setTranslationVersionName: setVersionName,
        setTranslationKeysLanguageOverride: setLanguageOverride,
        setGeoOverride: setGeolocationOverride,
        enableKeyDetails: () => setDetailsVisible(true),
        disableKeyDetails: () => setDetailsVisible(false),
        clearData: clearData,
    };

    return <PreviewContext.Provider value={sharedState}>{children}</PreviewContext.Provider>;
}

export function usePreviewContext() {
    return useContext(PreviewContext);
}
