import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './FooterApps.module.css';
import { ReactComponent as SVGApple } from './assets/apple.svg';
import { ReactComponent as SVGAppleDark } from './assets/apple_dark.svg';
import { ReactComponent as SVGGoogle } from './assets/google.svg';
import { ReactComponent as SVGGoogleDark } from './assets/google_dark.svg';

interface FooterAppsProps {
    darkMode?: boolean;
}

const FooterApps = ({ darkMode = false }: FooterAppsProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    return (
        <div className={clsx(styles.apps, darkMode ? styles.darkMode : '')}>
            <h3>{t('app_header')}</h3>
            <ul className={styles['apps-list']}>
                <li>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.implix.getresponse&utm_source=business-page&utm_campaign=footer"
                        data-test="FooterAppsLink"
                        aria-label="App Store"
                        rel="noopener noreferrer nofollow"
                    >
                        {darkMode ? (
                            <SVGGoogleDark
                                width="117"
                                height="42"
                            />
                        ) : (
                            <SVGGoogle
                                width="117"
                                height="42"
                            />
                        )}
                    </a>
                </li>
                <li>
                    <a
                        href="https://apps.apple.com/app/apple-store/id479252055?pt=582425&ct=business-page-footer&mt=8"
                        data-test="FooterAppsLink"
                        aria-label="Google Play"
                        rel="noopener noreferrer nofollow"
                    >
                        {darkMode ? (
                            <SVGAppleDark
                                width="117"
                                height="42"
                            />
                        ) : (
                            <SVGApple
                                width="117"
                                height="42"
                            />
                        )}
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default FooterApps;
