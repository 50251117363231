import { getMatchingRoute } from '../lib/routing';

export default function getPageLocales(pathname: string) {
    const route = getMatchingRoute(pathname);

    if (!route) {
        throw new Error(`Route ${pathname} not found in config/routes.js`);
    }

    return route.localization.filter((l: any) => !l.disabled).map((l: any) => l.locale);
}
