import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { usePreviewContext } from '@context/PreviewContext';

import styles from './PreviewData.module.css';

const PROD_TRANSLATION_VERSION_NAME = 'prod';

const storeTranslationVersionName = (translationVersionName) => {
    if (translationVersionName !== '') {
        const storedVersions = JSON.parse(localStorage.getItem('translationVersionNames')) ?? [];
        storedVersions.push(translationVersionName);
        localStorage.setItem('translationVersionNames', JSON.stringify([...new Set(storedVersions)]));
    }
};

export default function PreviewData() {
    const previewContext = usePreviewContext();
    const { locale, locales } = useRouter();

    const [popupVisible, setPopupVisible] = useState(false);
    const [translationVersionName, setTranslationVersionName] = useState(previewContext.translationVersionName);
    const [translationKeysLanguageOverride, setTranslationKeysLanguageOverride] = useState(previewContext.translationKeysLanguageOverride);
    const [geoOverride, setGeoOverride] = useState(previewContext.geoOverride);
    const [translationVersionNames, setTranslationVersionNames] = useState([]);

    useEffect(() => {
        setTranslationVersionName(previewContext.translationVersionName);
        setTranslationKeysLanguageOverride(previewContext.translationKeysLanguageOverride);
        setGeoOverride(previewContext.geoOverride);
        setTranslationVersionNames(JSON.parse(localStorage.getItem('translationVersionNames')) ?? []);
    }, [previewContext]);

    const changeTranslationVersion = async (translationVersionName, translationKeysLanguageOverride, geoOverride) => {
        storeTranslationVersionName(translationVersionName);
        const currentNames = JSON.parse(localStorage.getItem('translationVersionNames')) ?? [];
        setTranslationVersionNames([...new Set([...currentNames, translationVersionName])]);
        await previewContext.setTranslationVersionName(translationVersionName);
        await previewContext.setTranslationKeysLanguageOverride(translationKeysLanguageOverride);
        await previewContext.setGeoOverride(geoOverride);
        window.location.reload(true);
    };

    const isDirty = () => {
        return !([PROD_TRANSLATION_VERSION_NAME, ''].includes(translationVersionName) && (typeof translationKeysLanguageOverride === 'undefined' || locale.replace('vn', 'vi') === translationKeysLanguageOverride));
    };

    const handleClearData = async () => {
        await previewContext.clearData();
        window.location.reload(true);
    };

    return (
        <>
            <button
                onClick={() => setPopupVisible(!popupVisible)}
                data-ats-preview="trigger"
                className={[styles.preview, !isDirty() ? '' : styles.notDefault].join(' ')}
            >
                π
            </button>
            {popupVisible && (
                <dialog
                    className={styles.popup}
                    open
                >
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            changeTranslationVersion(translationVersionName, translationKeysLanguageOverride, geoOverride);
                        }}
                    >
                        <label
                            htmlFor="translation-version-name"
                            className={styles.label}
                        >
                            Translation version name:
                        </label>
                        <input
                            className={styles.input}
                            type="search"
                            id="translation-version-name"
                            list="translation-version-names"
                            data-ats-preview="version-names"
                            disabled={true}
                            onInput={(e) => setTranslationVersionName(e.target.value)}
                            value={translationVersionName}
                            placeholder={PROD_TRANSLATION_VERSION_NAME}
                        />
                        <datalist id="translation-version-names">
                            {translationVersionNames.map((val) => (
                                <option
                                    key={val}
                                    value={val}
                                />
                            ))}
                        </datalist>
                        <label
                            htmlFor="translation-lang-override"
                            className={styles.label}
                        >
                            Override translation keys language:
                        </label>
                        <select
                            className={styles.input}
                            id="translation-lang-override"
                            data-ats-preview="lang-override"
                            value={translationKeysLanguageOverride ? translationKeysLanguageOverride : locale.replace('vn', 'vi')}
                            onChange={(e) => setTranslationKeysLanguageOverride(e.target.value)}
                        >
                            {locales.map((locale) => (
                                <option key={locale}>{locale.replace('vn', 'vi')}</option>
                            ))}
                        </select>
                        <label
                            htmlFor="translation-geo-override"
                            className={styles.label}
                        >
                            Override geolocation:
                        </label>
                        <select
                            className={styles.input}
                            id="translation-geo-override"
                            data-ats-preview="geo-override"
                            value={geoOverride ? geoOverride : 'none'}
                            onChange={(e) => setGeoOverride(e.target.value)}
                        >
                            {[
                                { ISOCode: 'US', name: 'USA' },
                                { ISOCode: 'PL', name: 'Poland' },
                                { ISOCode: 'GB', name: 'United Kingdom' },
                                { ISOCode: 'DE', name: 'Germany' },
                                { ISOCode: 'BR', name: 'Brazil' },
                                { ISOCode: 'MY', name: 'Malaysia' },
                                { ISOCode: 'IN', name: 'India' },
                                { ISOCode: 'MX', name: 'Mexico' },
                                { ISOCode: 'PT', name: 'Portugal' },
                                { ISOCode: 'ES', name: 'Spain' },
                                { ISOCode: 'IT', name: 'Italy' },
                                { ISOCode: 'FR', name: 'France' },
                                { ISOCode: 'AU', name: 'Australia' },
                                { ISOCode: 'CL', name: 'Chile' },
                                { ISOCode: 'NG', name: 'Nigeria' },
                                { ISOCode: 'VN', name: 'Vietnam' },
                                { ISOCode: 'CH', name: 'Switzerland' },
                                { ISOCode: 'CO', name: 'Colombia' },
                                { ISOCode: 'SG', name: 'Singapore' },
                            ]
                                .sort((a, b) => {
                                    return a.name > b.name ? 1 : -1;
                                })
                                .map((location) => (
                                    <option
                                        key={location.ISOCode}
                                        value={location.ISOCode}
                                    >
                                        {location.name}
                                    </option>
                                ))}
                        </select>
                        <label className={styles.label}>
                            <input
                                className={styles.checkbox}
                                type="checkbox"
                                data-ats-preview="keys_visible"
                                checked={previewContext.translationKeysDetailsVisible}
                                onChange={(e) => (e.target.checked ? previewContext.enableKeyDetails() : previewContext.disableKeyDetails())}
                            />{' '}
                            show translation keys details
                        </label>
                        <button
                            type="submit"
                            data-ats-preview="submit_button"
                        >
                            Submit
                        </button>
                        <button
                            style={{ float: 'right', color: 'red' }}
                            type="button"
                            data-ats-preview="clear_button"
                            onClick={handleClearData}
                        >
                            Clear preview data
                        </button>
                    </form>
                </dialog>
            )}
        </>
    );
}
