import getConfig from 'next/config';
import router, { useRouter as useRouterFromNextJs } from 'next/dist/api/router';

const { publicRuntimeConfig } = getConfig();

export const useRouter = () => {
    const router = useRouterFromNextJs();
    if (router.locale) {
        return router;
    } else {
        return {
            ...router,
            pathname: router.pathname.replace(/^\/\[locale\]/, ''),
            locale: [router.query.locale ?? 'en'].flat()[0].replace('vi', 'vn'),
            locales: publicRuntimeConfig.locales,
            domains: publicRuntimeConfig.environment_domains,
        };
    }
};
export default router;
