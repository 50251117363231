import { cloneElement, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';

import { ThemeProvider } from 'next-themes';
import appWithI18n from 'next-translate/appWithI18n';

import { AppWrapper } from '@context/AppContext';
import { PreviewWrapper } from '@context/PreviewContext';

import GrAutomationScript from '@components/global/GrAutomationScript';
import PreviewData from '@components/global/PreviewData';
import Layout from '@components/layouts/Layout';

import { reportWebVitals } from '@helpers/reportWebVitals';

import loraBasic from '@public/fonts/Lora/Lora-basic.woff2';
import robotoFlexBasic from '@public/fonts/RobotoFlex/RobotoFlex-basic.woff2';
import rubikBasic from '@public/fonts/Rubik/Rubik-basic.woff2';
import sourceSans3Basic from '@public/fonts/SourceSans3/SourceSans3-basic.woff2';

import { useForceHtmlLangAttribute } from '@hooks/useForceHtmlLangAttribute';

import '@styles/colors.css';
import '@styles/fonts/lora.css';
import '@styles/fonts/notoserif.css';
import '@styles/fonts/ptserif.css';
import '@styles/fonts/robotoflex.css';
import '@styles/fonts/rubik.css';
import '@styles/fonts/sourcesans3.css';
import '@styles/globals.css';

import ErrorBoundary from '../components/global/ErrorBoundary';
import i18nConfig from '../i18n';

const LocalizationKeysPreview = dynamic(() => import('@components/LocalizationKeysPreview'), {
    ssr: false,
    loading: () => {
        return <></>;
    },
});

const GoogleTagManager = dynamic(() => import('@components/layouts/GoogleTagManager'), {
    ssr: false,
    loading: () => {
        return <></>;
    },
});

export { reportWebVitals };

function MyApp({ Component, pageProps, previewNamespaces, ...props }) {
    const isPreview = props.router.isPreview;
    const getLayout = Component.getLayout || ((page) => <Layout pageProps={pageProps}>{page}</Layout>);
    const pageWithLayout = getLayout(<></>);
    const pageWithLayoutWithPageProps = cloneElement(
        pageWithLayout,
        { pageProps },
        <ErrorBoundary>
            <Component {...pageProps} />
        </ErrorBoundary>,
    );
    const [isClient, setIsClient] = useState(false);

    useForceHtmlLangAttribute(props?.router?.locale?.replace('vn', 'vi'));

    if (pageProps?.statusCode === 405) {
        return <Component {...pageProps} />;
    }

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    href={robotoFlexBasic}
                    as="font"
                    type="font/woff2"
                    crossOrigin="true"
                />
                <link
                    rel="preload"
                    href={sourceSans3Basic}
                    as="font"
                    type="font/woff2"
                    crossOrigin="true"
                />
                <link
                    rel="preload"
                    href={rubikBasic}
                    as="font"
                    type="font/woff2"
                    crossOrigin="true"
                />
                <link
                    rel="preload"
                    href={loraBasic}
                    as="font"
                    type="font/woff2"
                    crossOrigin="true"
                />
            </Head>
            {isClient && new URLSearchParams(window.location.search).get('gtm') !== 'false' && <GoogleTagManager />}
            <GrAutomationScript />

            <ThemeProvider
                attribute="class"
                value={{
                    'high-contrast': 'theme-high-contrast',
                    default: 'theme-default',
                }}
                defaultTheme="default"
            >
                {isPreview ? (
                    <PreviewWrapper>
                        <PreviewData />
                        <LocalizationKeysPreview />
                        <AppWrapper
                            defaultPricing={pageProps.pricing}
                            defaultCreatorPricing={pageProps.pricingCreator}
                        >
                            {pageWithLayoutWithPageProps}
                        </AppWrapper>
                    </PreviewWrapper>
                ) : (
                    <AppWrapper
                        defaultPricing={pageProps.pricing}
                        defaultCreatorPricing={pageProps.pricingCreator}
                    >
                        {pageWithLayoutWithPageProps}
                    </AppWrapper>
                )}
            </ThemeProvider>
        </>
    );
}

export default appWithI18n(MyApp, {
    ...i18nConfig,
    skipInitialProps: true,
});
