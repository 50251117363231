import { useRouter } from 'next/router';

import { locales } from '@/config/i18n';
import { getLocalizedUrl } from '@/lib/routing';

export const getNextLink = (href, locale) => {
    const router = useRouter();
    let pathname = href.pathname;
    if (!pathname) {
        throw new Error('No pathname');
    }
    if (pathname[0] !== '/') {
        pathname = `/${pathname}`;
    }
    let chosenLocale = locale || router.locale;
    if (!chosenLocale || !locales.includes(chosenLocale)) {
        chosenLocale = 'en';
    }
    const localizedUrl = getLocalizedUrl(pathname, chosenLocale);
    if (localizedUrl !== false) {
        return localizedUrl;
    }
    const defaultEnUrl = getLocalizedUrl(pathname, 'en');
    const urlObject = new URL(defaultEnUrl);
    if (href.hash) {
        urlObject.hash = href.hash;
    }
    if (href.search) {
        urlObject.search = href.search;
    }
    return urlObject.toString();
};
