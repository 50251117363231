import React from 'react';

import useTranslation from '@hooks/useTranslation';

import styles from './FooterManageCookie.module.css';

declare global {
    interface Window {
        OneTrust: any;
    }
}

export default function FooterManageCookie() {
    const { t } = useTranslation('base/_blocks/footer');
    const manageCookie = () => {
        if (window['OneTrust'] !== undefined) {
            window['OneTrust'].ToggleInfoDisplay();
        }
    };
    return (
        <button
            className={styles.action}
            data-ats="manage-cookie"
            onClick={manageCookie}
        >
            {t('menu_cookies')}
        </button>
    );
}
