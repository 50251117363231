// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    beforeSend(event) {
        try {
            if (event.exception?.values?.[0]?.stacktrace?.frames?.some((f) => f.filename?.includes(`https://www.googletagmanager.com/gtm.js`))) {
                return null;
            }
        } catch (e) {}

        return event;
    },
    dsn: SENTRY_DSN || 'https://de64899a9c014e168a534c6f9491eed5@o566338.ingest.sentry.io/5971349',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    allowUrls: [
        /https:\/\/(beta-)?www\.getresponse\.com/,
        /https:\/\/(beta-)?www\.getresponse\.pl/,
        /https:\/\/(beta-)?www\.getresponse\.ru/,
        /https:\/\/(beta-)?cn\.getresponse\.com/,
        /https:\/\/(beta-)?www\.getresponse\.my/,
        /https:\/\/(beta-)?www\.getresponse\.com\.tr/,
        /https:\/\/(beta-)?www\.getresponse\.co\.id/,
        /https:\/\/(beta-)?www\.getresponse\.nl/,
        /https:\/\/us-wn\.gr-cdn\.com/,
    ],
    ignoreErrors: ['Non-Error promise rejection captured', 'TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.', 'TypeError: Load failed'],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
