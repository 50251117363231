import styles from './Container.module.css';

interface ContainerProps {
    children?: React.ReactNode;
    maxWidth?: string;
    otherProps?: object;
    customStyles?: {
        container?: string;
    };
}

export default function Container({ children, maxWidth, customStyles, ...otherProps }: ContainerProps): JSX.Element {
    return (
        <div
            className={[styles.container, customStyles?.container].filter(Boolean).join(' ')}
            style={maxWidth ? { maxWidth } : {}}
            {...otherProps}
        >
            {children}
        </div>
    );
}
