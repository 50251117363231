import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
export const laravelRouteApi = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiHomeVersion2 = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2BlogAuthor = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/blog/author",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/blog/author",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/blog/author",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/blog/author",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/blog/author",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/blog/author",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/blog/author",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/blog/author",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/blog/author"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2BlogAutocomplete = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/blog/autocomplete",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/blog/autocomplete",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/blog/autocomplete",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/blog/autocomplete",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/blog/autocomplete",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/blog/autocomplete",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/blog/autocomplete",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/blog/autocomplete",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/blog/autocomplete"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2BlogSearch = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/blog/search",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/blog/search",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/blog/search",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/blog/search",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/blog/search",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/blog/search",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/blog/search",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/blog/search",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/blog/search"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2BlogSubscribe = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/blog/subscribe"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Services = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/services",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/services",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/services",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/services",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/services",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/services",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/services",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/services",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/services"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Languages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/languages",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/languages",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/languages",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/languages",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/languages",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/languages",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/languages",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/languages",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/languages"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Search = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/search",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/search",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/search",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/search",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/search",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/search",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/search",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/search",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/search"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Autocomplete = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/search/autocomplete",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/search/autocomplete",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/search/autocomplete",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/search/autocomplete",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/search/autocomplete",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/search/autocomplete",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/search/autocomplete",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/search/autocomplete",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/search/autocomplete"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2HelpAutocomplete = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/help/autocomplete",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/help/autocomplete",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/help/autocomplete",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/help/autocomplete",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/help/autocomplete",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/help/autocomplete",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/help/autocomplete",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/help/autocomplete",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/help/autocomplete"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2IntegrationsAutocomplete = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/integrations/autocomplete",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/integrations/autocomplete",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/integrations/autocomplete",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/integrations/autocomplete",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/integrations/autocomplete",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/integrations/autocomplete",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/integrations/autocomplete",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/integrations/autocomplete",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/integrations/autocomplete"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Subscribe = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/subscribe"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2SubscribeWebinar = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/subscribe/webinar"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Translations = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/translations",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/translations",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/translations",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/translations",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/translations",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/translations",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/translations",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/translations",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/translations"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2TranslationsCountries = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/translations/countries",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/translations/countries",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/translations/countries",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/translations/countries",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/translations/countries",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/translations/countries",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/translations/countries",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/translations/countries",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/translations/countries"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2Esps = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/esps",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/esps",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/esps",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/esps",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/esps",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/esps",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/esps",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/esps",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/esps"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2PartnersAgency = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/partners/agency"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2ResourcesAutocomplete = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/resources/autocomplete",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/resources/autocomplete",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/resources/autocomplete",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/resources/autocomplete",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/resources/autocomplete",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/resources/autocomplete",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/resources/autocomplete",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/resources/autocomplete",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/resources/autocomplete"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteResourcesDownloadPdf = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/resources/download/pdf/{postType}/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2WbeCategories = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/wbe/categories",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/wbe/categories",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/wbe/categories",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/wbe/categories",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/wbe/categories",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/wbe/categories",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/wbe/categories",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/wbe/categories",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/wbe/categories"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2WbeTags = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/wbe/tags",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/wbe/tags",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/wbe/tags",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/wbe/tags",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/wbe/tags",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/wbe/tags",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/wbe/tags",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/wbe/tags",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/wbe/tags"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteApiV2WbeTemplates = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/api/v2/wbe/templates",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/api/v2/wbe/templates",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/api/v2/wbe/templates",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/api/v2/wbe/templates",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/api/v2/wbe/templates",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/api/v2/wbe/templates",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/api/v2/wbe/templates",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/api/v2/wbe/templates",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/api/v2/wbe/templates"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteHomePage = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + ""
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSearch = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/search",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/search",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/search",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/search",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/search",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/search",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/search",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/search",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/search"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteResources = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/resources",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/baza-wiedzy",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/resources",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/resources",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/recursos",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/resources"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteResourcesCollections = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/resources/collections/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteResourcesSingleCoursesPage = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/resources/courses/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteIntegrations = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/integrations",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/integracje"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteIntegrationsSingle = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/integrations/{slug}.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/integracje/{slug}.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteIntegrationsSubmitYourIntegration = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/integrations/submit-your-integration",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/integracje/wyslij-swoja-integracje"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMarketplace = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/marketplace",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/marketplace",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/marketplace",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/marketplace",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/marketplace",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/marketplace",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/marketplace",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/marketplace",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/marketplace"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMarketplaceListing = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/marketplace/listing",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/marketplace/listing",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/marketplace/oferty",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/marketplace/listing",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/marketplace/listing",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/marketplace/listing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/marketplace/listing",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/marketplace/listing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/marketplace/listing"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMarketplaceSingle = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/marketplace/{slug}.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/marketplace/{slug}.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMarketplaceJoin = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/marketplace/join",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/marketplace/join",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/marketplace/dolacz",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/marketplace/join",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/marketplace/join",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/marketplace/join",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/marketplace/join",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/marketplace/join",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/marketplace/join"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBlog = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/blog",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/blog",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/blog",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/blog",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/blog",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/blog",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/blog",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/blog"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBlogFeed = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/blog/feed",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/blog/feed",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/blog/feed",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/blog/feed",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/blog/feed",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/blog/feed",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/blog/feed",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/blog/feed",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/blog/feed"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBlogRss2Mail = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/blog/rss2mail",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/blog/rss2mail",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/blog/rss2mail",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/blog/rss2mail",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/blog/rss2mail",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/blog/rss2mail",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/blog/rss2mail",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/blog/rss2mail",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/blog/rss2mail"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBlogSearch = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/blog/search",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/blog/search",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/blog/search",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/blog/search",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/blog/search",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/blog/search",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/blog/search",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/blog/search",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/blog/search"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBlogWriteForUs = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/blog/write-for-us"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBlogSingle = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/blog/{slug}",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/blog/{slug}",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/blog/{slug}",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/blog/{slug}",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/blog/{slug}",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/blog/{slug}",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/blog/{slug}",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/blog/{slug}",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/blog/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMarketingToolkit = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/marketing-toolkit",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/pt-marketing-toolkit",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/niezbednik-marketera",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-marketing-toolkit",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/marketing-toolkit",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/marketing-toolkit",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/marketing-toolkit",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/marketing-toolkit",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/marketing-toolkit"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteHelp = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/help",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/ajuda",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pomoc",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/help-ru",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/hilfe",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/ayuda",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/aide",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/assistenza"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteHelpSearch = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/help/search",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/ajuda/buscar",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pomoc/szukaj",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/help-ru/search",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/hilfe/suche",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/ayuda/buscar",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/aide/search",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/assistenza/cerca",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/help/search"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteHelpSingle = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/help/{slug}.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/ajuda/{slug}.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pomoc/{slug}.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/help-ru/{slug}.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/hilfe/{slug}.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/ayuda/{slug}.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/aide/{slug}.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/assistenza/{slug}.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteHelpTopic = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/help/topic/{slug}",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/ajuda/topic/{slug}",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pomoc/topic/{slug}",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/help-ru/topic/{slug}",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/hilfe/topic/{slug}",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/ayuda/topic/{slug}",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/aide/topic/{slug}",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/assistenza/topic/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteHelpCategory = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/help/{category}",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/ajuda/{category}",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pomoc/{category}",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/help-ru/{category}",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/hilfe/{category}",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/ayuda/{category}",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/aide/{category}",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/assistenza/{category}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxChangeLanguage = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/change-language",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/change-language",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/zmien-jezyk",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/izmenit-yazyk",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/sprache-andern",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/cambiar-idioma",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/changer-de-langue",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/cambia-lingua",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/change-language"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxCrossChannelCommunication = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/cross-channel-communication",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/cross-channel-communication",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/cross-channel-communication",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/cross-channel-communication",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/cross-channel-communication",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/cross-channel-communication",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/cross-channel-communication",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/cross-channel-communication",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/cross-channel-communication"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxEngagementAndRetention = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/engagement-and-retention",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/engagement-and-retention",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/engagement-and-retention",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/engagement-and-retention",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/engagement-and-retention",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/engagement-and-retention",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/engagement-and-retention",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/engagement-and-retention",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/engagement-and-retention"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxLeadAcquisition = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/lead-acquisition",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/lead-acquisition",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/lead-acquisition",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/lead-acquisition",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/lead-acquisition",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/lead-acquisition",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/lead-acquisition",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/lead-acquisition",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/lead-acquisition"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutions = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutionsECommerce = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions/e-commerce",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions/e-commerce",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions/e-commerce",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions/e-commerce",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions/e-commerce",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions/e-commerce",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions/e-commerce",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions/e-commerce",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions/e-commerce"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutionsMarketingAgencies = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions/marketing-agencies",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions/marketing-agencies",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions/marketing-agencies",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions/marketing-agencies",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions/marketing-agencies",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions/marketing-agencies",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions/marketing-agencies",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions/marketing-agencies",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions/marketing-agencies"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutionsEducation = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions/education",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions/education",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions/education",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions/education",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions/education",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions/education",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions/education",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions/education",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions/education"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutionsTravelTourism = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions/travel-tourism",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions/travel-tourism",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions/travel-tourism",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions/travel-tourism",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions/travel-tourism",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions/travel-tourism",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions/travel-tourism",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions/travel-tourism",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions/travel-tourism"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutionsOnlineMarketing = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions/online-marketing",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions/online-marketing",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions/online-marketing",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions/online-marketing",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions/online-marketing",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions/online-marketing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions/online-marketing",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions/online-marketing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions/online-marketing"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIndustrySolutionsFitnessWellness = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/industry-solutions/fitness-wellness",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/industry-solutions/fitness-wellness",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/industry-solutions/fitness-wellness",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/industry-solutions/fitness-wellness",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/industry-solutions/fitness-wellness",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/industry-solutions/fitness-wellness",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/industry-solutions/fitness-wellness",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/industry-solutions/fitness-wellness",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/industry-solutions/fitness-wellness"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIntegrations = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/integrations",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/integrations",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/integrations",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/integrations",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/integrations",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/integrations",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/integrations",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/integrations",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/integrations"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxIntegrationsMicrosoftDynamics = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/integrations/microsoft-dynamics",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/integrations/microsoft-dynamics",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/integrations/microsoft-dynamics",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/integrations/microsoft-dynamics",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/integrations/microsoft-dynamics",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/integrations/microsoft-dynamics",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/integrations/microsoft-dynamics",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/integrations/microsoft-dynamics",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/integrations/microsoft-dynamics"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxContactUs = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/contact-us",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/contact-us",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/contact-us",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/contact-us",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/contact-us",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/contact-us",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/contact-us",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/contact-us",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/contact-us"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxSupportAndSecurity = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/support-and-security",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/support-and-security",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/support-and-security",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/support-and-security",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/support-and-security",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/support-and-security",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/support-and-security",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/support-and-security",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/support-and-security"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxSupportAndSecurityDedicatedSupport = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/support-and-security/dedicated-support",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/support-and-security/dedicated-support",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/support-and-security/dedicated-support",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/support-and-security/dedicated-support",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/support-and-security/dedicated-support",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/support-and-security/dedicated-support",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/support-and-security/dedicated-support",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/support-and-security/dedicated-support",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/support-and-security/dedicated-support"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxSupportAndSecurityDeliverability = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/support-and-security/deliverability",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/support-and-security/deliverability",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/support-and-security/deliverability",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/support-and-security/deliverability",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/support-and-security/deliverability",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/support-and-security/deliverability",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/support-and-security/deliverability",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/support-and-security/deliverability",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/support-and-security/deliverability"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxSupportAndSecurityDataCompliance = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/support-and-security/data-compliance",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/support-and-security/data-compliance",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/support-and-security/data-compliance",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/support-and-security/data-compliance",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/support-and-security/data-compliance",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/support-and-security/data-compliance",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/support-and-security/data-compliance",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/support-and-security/data-compliance",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/support-and-security/data-compliance"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxSupportAndSecurityAssistedIpWarmup = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/support-and-security/assisted-ip-warmup",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/support-and-security/assisted-ip-warmup"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxSupportAndSecurityOnboarding = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/support-and-security/onboarding",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/support-and-security/onboarding",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/support-and-security/onboarding",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/support-and-security/onboarding",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/support-and-security/onboarding",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/support-and-security/onboarding",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/support-and-security/onboarding",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/support-and-security/onboarding",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/support-and-security/onboarding"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMax = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxBookADemo = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/book-a-demo",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/book-a-demo",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/book-a-demo",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/book-a-demo",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/book-a-demo",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/book-a-demo",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/book-a-demo",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/book-a-demo",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/book-a-demo"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxGetAQuote = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/get-a-quote",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/get-a-quote",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/get-a-quote",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/get-a-quote",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/get-a-quote",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/get-a-quote",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/get-a-quote",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/get-a-quote",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/get-a-quote"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxPartners = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/partners",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/partners",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/partners",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/partners",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/partners",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/partners",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/partners",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/partners",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/partners"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMaxB2B = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/max/b2b",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/max/b2b",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/max/b2b",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/max/b2b",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/max/b2b",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/max/b2b",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/max/b2b",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/max/b2b",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/max/b2b"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAbout = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/about",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/sobre-getresponse",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/o-getresponse",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-about",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/uber-getresponse",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/acerca-de-getresponse",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/a-propos-de-getresponse",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/informazioni",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/about"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAboutContactUs = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/about/contact-us",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/sobre-getresponse/contato",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/o-getresponse/kontakt",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-about/contact-us",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/uber-getresponse/kontakt",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/acerca-de-getresponse/contacto",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/a-propos-de-getresponse/contactez",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/informazioni/contatti",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/about/contact-us"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAboutWhyGetresponse = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/about/why-getresponse",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/sobre-getresponse/por-que-a-getresponse",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/o-getresponse/dlaczego-getresponse",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-about/why-getresponse",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/uber-getresponse/warum-getresponse",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/acerca-de-getresponse/por-que-getresponse",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/a-propos-de-getresponse/pourquoi-getresponse",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/informazioni/perche-getresponse",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/about/why-getresponse"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAboutBrandAssets = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/about/brand-assets",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/sobre-getresponse/ativos-de-marca",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/o-getresponse/znaki-firmowe",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-about/brand-assets",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/uber-getresponse/marken-assets",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/acerca-de-getresponse/activos-de-marca",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/a-propos-de-getresponse/ressources-de-la-marque",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/informazioni/risorse-di-branding",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/about/brand-assets"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAboutPressCenter = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/about/press-center",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/o-getresponse/centrum-prasowe"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAboutPressCenterSendMessage = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/about/press-center"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSitemap = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/{sitemapName}.xml"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteDmca = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/dmca-takedown-form"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnerPayu = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partner/payu",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partner/payu",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partner/payu",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partner/payu",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partner/payu",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partner/payu",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partner/payu",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partner/payu",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partner/payu"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnerRevolut = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partner/revolut",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partner/revolut",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partner/revolut",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partner/revolut",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partner/revolut",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partner/revolut",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partner/revolut",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partner/revolut",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partner/revolut"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnerPrestashop = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partner/prestashop",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partner/prestashop",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partner/prestashop",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partner/prestashop",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partner/prestashop",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partner/prestashop",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partner/prestashop",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partner/prestashop",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partner/prestashop"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnerPackhelp = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partner/packhelp",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partner/packhelp",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partner/packhelp",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partner/packhelp",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partner/packhelp",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partner/packhelp",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partner/packhelp",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partner/packhelp",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partner/packhelp"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnerPfr = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partner/pfr",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partner/pfr",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partner/pfr",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partner/pfr",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partner/pfr",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partner/pfr",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partner/pfr",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partner/pfr",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partner/pfr"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnerTechsoup = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partner/techsoup",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partner/techsoup",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partner/techsoup",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partner/techsoup",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partner/techsoup",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partner/techsoup",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partner/techsoup",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partner/techsoup",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partner/techsoup"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAffiliateProgramsRecurringSignup = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/affiliate-programs/recurring/signup",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/pt-programas-de-afiliados/recurring/signup",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/programy-afiliacyjne/recurring/rejestracja",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/ru-affiliate-programs/recurring/signup",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/affiliate-programme/wiederkehrend/registrierung",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/programas-de-afiliados/recurrente/suscripcion",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/programmes-d-affiliation/recurring/signup",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/programmi-di-affiliazione/recurring/signup",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/affiliate-programs/recurring/signup"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteAffiliateProgramsBountySignup = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/affiliate-programs/bounty/signup",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/pt-programas-de-afiliados/bounty/signup",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/programy-afiliacyjne/bounty/rejestracja",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/ru-affiliate-programs/bounty/signup",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/affiliate-programme/pramie/registrierung",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/programas-de-afiliados/recompensa/suscripcion",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/programmes-d-affiliation/bounty/signup",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/programmi-di-affiliazione/bounty/signup",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/affiliate-programs/bounty/signup"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeatures = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/ru-features",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesWebsiteBuilder = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/website-builder",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/website-builder",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/kreator-stron-www",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/ru-features/konstruktor-sajtov",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/website-erstellen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/website-builder",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/website-builder",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/creare-sito-web",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/website-builder"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesWebsiteBuilderTemplates = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/website-builder/templates",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/website-builder/templates",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/kreator-stron-www/szablony",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/website-erstellen/vorlagen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/website-builder/plantillas",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/creare-sito-web/template",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/website-builder/templates"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesWebsiteBuilderTemplatesPreview = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/website-builder/templates/preview/{uuid}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteMobileAppLoginLink = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/mobile-app/login-link",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/mobile-app/login-link",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/mobile-app/login-link",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/mobile-app/login-link",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/mobile-app/login-link",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/mobile-app/login-link",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/mobile-app/login-link",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/mobile-app/login-link",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/mobile-app/login-link"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesConversionFunnel = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/conversion-funnel",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/funil-de-conversao",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/lejek-konwersji",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/voronka-konversii",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/conversion-funnel",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/embudo-de-conversion",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/funnel-de-conversion",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/conversion-funnel",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/conversion-funnel"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesConversionFunnelLeadMagnet = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/conversion-funnel/lead-magnet",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/funil-de-conversao/ima-de-leads",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/lejek-konwersji/lead-magnety",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/voronka-konversii/lid-magnity",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/conversion-funnel/lead-magnet",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/embudo-de-conversion/lead-magnet",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/funnel-de-conversion/lead-magnet",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/conversion-funnel/lead-magnet-marketing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/conversion-funnel/lead-magnet"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesPaidAdsFacebookAds = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/paid-ads/facebook-ads",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/anuncios-pagos/anuncios-do-facebook",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/reklama-online/facebook-ads",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/platnaja-reklama/facebook-ads",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/bezahlte-anzeigen/facebook-anzeigen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/anuncios-pagos/anuncios-de-facebook",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/publicites-payantes/annonces-facebook",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/paid-ads/annunci-facebook",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/paid-ads/facebook-ads"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesEmailMarketingAutoresponder = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/email-marketing/autoresponder",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/marketing-por-e-mail/autoresponder",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/email-marketing/autoresponder",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/email-marketing/autoresponder",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/e-mail-marketing/autoresponder",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/autoresponder",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/marketing-par-e-mail/repondeur_automatique",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/email-marketing/autorisponditore",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/autoresponder"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesEmailMarketingEmailCreator = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/email-marketing/email-creator",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/marketing-por-e-mail/email-creator",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/email-marketing/kreator-wiadomosci",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/email-marketing/email-creator",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/e-mail-marketing/email-creator",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/email-creator",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/marketing-par-e-mail/createur_d_email",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/email-marketing/generatore-email",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/email-creator"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesEmailMarketing = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/email-marketing",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/marketing-por-e-mail",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/email-marketing",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/email-marketing",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/e-mail-marketing",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/email-marketing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/marketing-par-e-mail",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/email-marketing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/email-marketing"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesEmailMarketingSocialMediaIntegrations = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/email-marketing/social-media-integrations",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/marketing-por-e-mail/integracoes-com-redes-sociais",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/email-marketing/integracje-social-media",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/email-marketing/social-media-integrations",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/e-mail-marketing/integration-sozialer-medien",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/integraciones-con-redes-sociales",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/marketing-par-e-mail/integrations-des-medias-sociaux",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/email-marketing/integrazione-con-i-social-media",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/social-media-integrations"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesEmailMarketingTeamManagement = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/email-marketing/team-management",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/marketing-por-e-mail/gestao-de-equipe",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/email-marketing/zarzadzanie-zespolem",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/email-marketing/team-management",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/e-mail-marketing/team-management",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/gestion-equipo",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/marketing-par-e-mail/gestion-d-equipe",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/email-marketing/gestione-team",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/team-management"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesLandingPageCreator = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/landing-page-creator",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/criador-de-pagina-de-destino",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/kreator-landing-pages",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/landing-page-creator",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/landing-page-creator",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/landing-page-creator",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/landing-page-creator",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/generatore-landing-page",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/landing-page-creator"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesMarketingAutomation = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/marketing-automation",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/automatizacao-de-marketing",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/marketing-automation",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/marketing-automation",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/marketingautomatisierung",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/automatizacion-de-marketing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/automatisation-du-marketing",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/marketing-automation",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/marketing-automation"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesMobileApps = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/mobile-apps",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/aplicativos-moveis",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/aplikacje-mobilne",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/mobile-apps",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/smartphone-apps",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/aplicaciones-moviles",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/applis-mobiles",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/app-per-dispositivi-mobili",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/mobile-apps"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesMobileLandingPage = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/mobile-landing-page",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/pagina-de-destino-movel",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/mobilne-landing-pages",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/mobile-landing-page",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/mobile-landing-page",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/pagina-de-destino-movil",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/page-de-destination-mobile",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/landing-page-mobile",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/mobile-landing-page"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesSupport = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/support",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/assistencia",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/wsparcie-techniczne",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/support",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/support",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/support",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/assistance",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/assistenza",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/support"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesUserBenefits = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/user-benefits",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/beneficios-para-o-usuario",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/twoje-korzysci",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/user-benefits",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/benutzervorteile",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/beneficios-para-el-usuario",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/avantages-utilisateur",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/vantaggi-per-l-utente",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/user-benefits"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesWebinarSoftware = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/webinar-software",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/software-de-webinar",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/webinar",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/webinar",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/webinar-software",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/software-de-webinar",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/logiciel-de-webinaire",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/webinar",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/webinar-software"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesPaidAds = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/paid-ads",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/anuncios-pagos",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/reklama-online",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/platnaja-reklama",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/bezahlte-anzeigen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/anuncios-pagos",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/publicites-payantes",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/paid-ads",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/paid-ads"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesWebPushNotifications = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/web-push-notifications",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/web-push-notifications",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/powiadomienia-web-push",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/web-push-uvedomlenija",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/web-push-notifications-tool",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/web-push-notifications",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/web-push-notifications",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/notifiche-push",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/web-push-notifications"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesListBuilding = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/list-building",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/construcao-de-lista",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/budowa-listy",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/list-building",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/listenaufbau",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/construccion-de-lista",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/construction-de-liste",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/creazione-liste",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/list-building"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesListBuildingFormBuilder = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/list-building/form-builder",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/construcao-de-lista/editor-formularios",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/budowa-listy/kreator-formularzy",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/list-building/form-builder",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/listenaufbau/form-builder",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/construccion-de-lista/generador-de-formulario",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/construction-de-liste/constructeur-formulaires",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/creazione-liste/generatore-moduli",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/list-building/form-builder"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesListBuildingHydraAntiSpam = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/list-building/hydra-anti-spam",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/construcao-de-lista/hydra-anti-spam",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/budowa-listy/hydra-anty-spam",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/list-building/hydra-anti-spam",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/listenaufbau/hydra-anti-spam",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/construccion-de-lista/hydra-anti-spam",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/construction-de-liste/hydra-anti-spam",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/creazione-liste/hydra-anti-spam",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/list-building/hydra-anti-spam"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesEmailMarketingTransactionalEmail = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/email-marketing/transactional-email",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/marketing-por-e-mail/email-de-transacao",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/email-marketing/emaile-transakcyjne",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/email-marketing/tranzakcionnoe-pismo",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/e-mail-marketing/transaktionale-e-mails",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/emails-transaccionales",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/marketing-par-e-mail/e-mail-transactionnel",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/email-marketing/transactional-email",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/transactional-email"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesLiveChat = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/live-chat",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/live-chat",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/live-chat",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/live-chat",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/live-chat-software",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/live-chat",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/live-chat",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/live-chat",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/live-chat"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesSmsTextAutomation = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/sms-text-automation",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/sms-marketing",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/sms-marketing",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/sms-rassylka",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/sms-marketing",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/sms-marketing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/sms-marketing",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/sms-marketing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/sms-marketing"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesPopupCreator = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/popup-creator",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/gerador-de-pop-up",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/kreator-pop-up",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-features/konstruktor-vsplyvayushchikh-okon",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/popup-maker",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/creador-de-pop-ups",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/createur-de-pop-up",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/come-creare-popup",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/tao-popup"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteFeaturesProductRecommendations = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/features/product-recommendations",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/recursos/sistema-de-recomendacao-de-produtos",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/funkcjonalnosci/rekomendacje-produktowe-ai",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/funktionen/ki-produktempfehlungen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/funciones/recomendador-de-productos",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/caracteristiques/recommandations-ia",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/funzionalita/recommendation-ai",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/features/de-xuat-ai"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSolutionsCustomerEngagement = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/solutions/customer-engagement",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/solucoes/envolvimento-do-cliente",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/rozwiazania/komunikuj-i-angazuj",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/resheniya/vovlechennost-klientov",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/loesungen/kundenbindung",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/soluciones/compromiso-del-cliente",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/solutions/engagement-client",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/soluzioni/customer-engagement-software",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/solutions/customer-engagement"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSolutionsLeadGenerationSoftware = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/solutions/lead-generation-software",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/solucoes/software-de-geracao-de-leads",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/rozwiazania/generowanie-leadow",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/resheniya/instrumenty-lidogeneratsii",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/loesungen/leadgenerierung-software",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/soluciones/software-de-generacion-de-contactos",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/solutions/logiciel-de-lead-generation",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/soluzioni/lead-generation-software",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/solutions/lead-generation-software"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSolutionsSellingKnowledge = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/solutions/selling-knowledge",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/solucoes/vender-conhecimento",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/rozwiazania/jak-sprzedawac-wiedze",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/resheniya/zarabotat-na-znanijah",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/loesungen/wissen-verkaufen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/soluciones/venta-de-conocimiento",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/solutions/vendre-son-expertise",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/soluzioni/vendere-know-how",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/solutions/selling-knowledge"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSolutionsEcommerceMarketing = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/solutions/ecommerce-marketing",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/solucoes/marketing-comercio-eletronico",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/rozwiazania/ecommerce-marketing",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/resheniya/ecommerce-marketing",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/loesungen/ecommerce-marketing",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/soluciones/e-commerce-marketing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/solutions/marketing-pour-e-commerce",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/soluzioni/ecommerce-marketing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/solutions/ecommerce-marketing"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSolutionsEcommerceMarketingShopify = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/solutions/ecommerce-marketing/shopify",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/solucoes/ecommerce-marketing/shopify",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/rozwiazania/ecommerce-marketing/shopify",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/resheniya/ecommerce-marketing/shopify",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/loesungen/ecommerce-marketing/shopify",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/soluciones/ecommerce-marketing/shopify",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/solutions/ecommerce-marketing/shopify",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/soluzioni/ecommerce-marketing/shopify",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/solutions/ecommerce-marketing/shopify"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePromoOnlineMarketer = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/promo/online-marketer",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/promo/online-marketer",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/promo/online-marketer",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-promo/online-marketer",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/promo/online-marketer",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/promo/online-marketer",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/promo/online-marketer",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/promo/online-marketer",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/promo/online-marketer"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePromoFitnessTrainer = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/promo/fitness-trainer",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/promo/fitness-trainer",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/promo/fitness-trainer",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-promo/fitness-trainer",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/promo/fitness-trainer",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/promo/fitness-trainer",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/promo/fitness-trainer",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/promo/fitness-trainer",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/promo/fitness-trainer"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteStartFree = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/start-free",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/start-free",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/start-free",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/start-free",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/start-free",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/start-free",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/start-free",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/start-free",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/start-free"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteGetresponseVsClickfunnels = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/getresponse-vs-clickfunnels",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/getresponse-vs-clickfunnels",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/getresponse-vs-clickfunnels",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/getresponse-vs-clickfunnels",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/getresponse-vs-clickfunnels",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/getresponse-vs-clickfunnels",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/getresponse-vs-clickfunnels",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/getresponse-vs-clickfunnels",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/getresponse-vs-clickfunnels"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteChangeLanguage = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/change-language",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/alterar-idioma",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/zmien-jezyk",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-change-language",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/sprache-andern",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/cambiar-idioma",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/changer-de-langue",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/cambia-lingua",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/change-language"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteWebinarLibrary = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/webinar-library",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/biblioteka-webinarow",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/webinar-bibliothek",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/raccolta-webinar"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteCustomers = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/customers",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/klienci",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/customers",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/customers"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteCustomersSingle = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/customers/{slug}",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/customers/{slug}",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/klienci/{slug}",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/customers/{slug}",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/customers/{slug}",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/customers/{slug}",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/customers/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplates = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesAboutMePages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/about-me-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-sobre-mim.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-o-mnie.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/about-me-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/infoseiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-sobre-mi.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-a-propos-de-moi.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-di-informazioni.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/about-me-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesDownloadPages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/download-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-download.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-pobierania.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/download-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/download-seiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-descarga.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-de-telechargement.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-di-download.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/download-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesOptInPages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/opt-in-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-assinatura.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-subskrypcji.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/opt-in-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/anmeldeseiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-suscripcion.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-d-inscription.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-di-opt-in.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/opt-in-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesPromoPages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/promo-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-promocao.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-promocyjne.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/promo-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/aktionsseiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-promocion.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-promo.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-promozionali.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/promo-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesSalesPages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/sales-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-venda.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-sprzedazowe.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/sales-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/verkaufsseiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-ventas.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-de-ventes.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-di-vendita.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/sales-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesStartFromScratch = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/start-from-scratch.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/mensagem-em-branco.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/zacznij-od-poczatku.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/start-from-scratch.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/bei-null-anfangen.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/empezar-desde-cero.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/partir-de-zero.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/inizia-da-zero.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/start-from-scratch.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesThankYouPages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/thank-you-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-agradecimento.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-z-podziekowaniem.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/thank-you-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/dankeseiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-agradecimiento.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-de-remerciement.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-di-ringraziamento.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/thank-you-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesVideoLandingPages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/video-landing-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-destino-em-video.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-wideo.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/video-landing-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/videoseiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-video.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-de-destination-video.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/landing-page-video.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/video-landing-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLandingPageTemplatesWebinarInvitePages = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/landing-page-templates/webinar-invite-pages.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/modelos-de-paginas-de-destino/paginas-de-convite-seminario-de-web.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/szablony-landing-pages/strony-webinarow.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-landing-page-templates/webinar-invite-pages.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/seitenvorlagen/webinar-seiten.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/modelos-de-pagina/paginas-de-webinar.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/modeles-de-pages-de-destination/pages-invitation-webinaires.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/template-landing-page/pagine-di-invito-ai-webinar.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/landing-page-templates/webinar-invite-pages.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLegal = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/legal",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/termos-de-servico",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/informacje-prawne",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/legal",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/legal",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/legal",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/legal",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/legal",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/legal"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteLegalSingle = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/legal/{slug}",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/termos-de-servico/{slug}",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/informacje-prawne/{slug}",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/legal/{slug}",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/legal/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEsps = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEspsMailchimp = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps/mailchimp",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps/mailchimp",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps/mailchimp",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps/mailchimp",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps/mailchimp",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps/mailchimp",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps/mailchimp",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps/mailchimp",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps/mailchimp"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEspsAweber = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps/aweber",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps/aweber",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps/aweber",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps/aweber",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps/aweber",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps/aweber",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps/aweber",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps/aweber",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps/aweber"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEspsKlaviyo = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps/klaviyo",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps/klaviyo",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps/klaviyo",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps/klaviyo",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps/klaviyo",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps/klaviyo",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps/klaviyo",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps/klaviyo",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps/klaviyo"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEspsSendinblue = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps/sendinblue",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps/sendinblue",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps/sendinblue",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps/sendinblue",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps/sendinblue",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps/sendinblue",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps/sendinblue",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps/sendinblue",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps/sendinblue"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEspsCleverreach = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps/cleverreach",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps/cleverreach",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps/cleverreach",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps/cleverreach",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps/cleverreach",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps/cleverreach",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps/cleverreach",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps/cleverreach",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps/cleverreach"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteEspsActivecampaign = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/esps/activecampaign",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/esps/activecampaign",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/esps/activecampaign",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/esps/activecampaign",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/esps/activecampaign",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/esps/activecampaign",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/esps/activecampaign",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/esps/activecampaign",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/esps/activecampaign"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteReferralProgram = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/referral-program",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/programa-de-referencias",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/program-polecajacy",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-referral-program",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/empfehlungsprogramm",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/programa-de-referidos",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/programme-de-parrainage",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/programma-di-referral",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/referral-program"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteReferralProgramReferAFriend = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/referral-program/refer-a-friend.html",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/programa-de-referencias/indique-um-amigo.html",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/program-polecajacy/zapros-znajomego.html",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-referral-program/refer-a-friend.html",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/empfehlungsprogramm/weiterempfehlen.html",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/programa-de-referidos/recomendar-un-amigo.html",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/programme-de-parrainage/parrainez-un-ami.html",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/programma-di-referral/consiglialo-agli-amici.html",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/referral-program/refer-a-friend.html"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteSecurity = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/security",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/seguranca",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/email-marketing/bezpieczenstwo",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/email-marketing/ru-security",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/sicherheit",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/seguridad",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/securite",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/sicurezza",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/security"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteNonProfit = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/non-profit",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/pt-non-profit",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pl-non-profit",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/ru-non-profit",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/non-profit",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/non-profit",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/non-profit",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/non-profit",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/non-profit"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePermissionSeal = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/permission-seal",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/permission-seal",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/permission-seal",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/permission-seal",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/permission-seal",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/permission-seal",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/permission-seal",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/permission-seal",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/permission-seal"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnersAgency = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partners/agency",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partners/agency",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partnerzy/agencje",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partners/agency",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partners/agency",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partners/agency",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partners/agency",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partners/agency",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partners/agency"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePartnersAgencyReferral = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/partners/agency/referral",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/partners/agency/referral",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/partnerzy/agencje/polecenie-klienta",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/partners/agency/referral",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/partners/agency/referral",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/partners/agency/referral",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/partners/agency/referral",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/partners/agency/referral",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/partners/agency/referral"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteBasicupsell = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/basicupsell",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/basicupsell",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/basicupsell",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/basicupsell",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/basicupsell",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/basicupsell",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/basicupsell",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/basicupsell",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/basicupsell"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteGo = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/go",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/go",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/go",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/go",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/go",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/go",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/go",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/go",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/go"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteGoStore = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/go"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePricing = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/pricing",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/pricing",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pricing",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/pricing",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/pricing",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/pricing",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/pricing",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/pricing",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/pricing"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePricingFree = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/pricing/free",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/pricing/free",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/pricing/getresponse-za-darmo",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/pricing/free",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/pricing/free",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/pricing/free",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/pricing/free",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/pricing/free",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/pricing/free"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRoutePpcPricingFtnu = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/ppc/pricing-ftnu"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteG2Reviews = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/g2-reviews",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/g2-reviews",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/g2-reviews",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/g2-reviews",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/g2-reviews",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/g2-reviews",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/g2-reviews",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/g2-reviews",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/g2-reviews"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteWebinars = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/webinars/{slug}"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                export const laravelRouteRobotsTxt = (locale) => {
                    const alternates = {
                        "en": publicRuntimeConfig.domains.en.protocol + publicRuntimeConfig.domains.en.domain + (publicRuntimeConfig.domains.en.prefix ? publicRuntimeConfig.domains.en.prefix : '') + "/robots.txt",
"pt": publicRuntimeConfig.domains.pt.protocol + publicRuntimeConfig.domains.pt.domain + (publicRuntimeConfig.domains.pt.prefix ? publicRuntimeConfig.domains.pt.prefix : '') + "/robots.txt",
"pl": publicRuntimeConfig.domains.pl.protocol + publicRuntimeConfig.domains.pl.domain + (publicRuntimeConfig.domains.pl.prefix ? publicRuntimeConfig.domains.pl.prefix : '') + "/robots.txt",
"ru": publicRuntimeConfig.domains.ru.protocol + publicRuntimeConfig.domains.ru.domain + (publicRuntimeConfig.domains.ru.prefix ? publicRuntimeConfig.domains.ru.prefix : '') + "/robots.txt",
"de": publicRuntimeConfig.domains.de.protocol + publicRuntimeConfig.domains.de.domain + (publicRuntimeConfig.domains.de.prefix ? publicRuntimeConfig.domains.de.prefix : '') + "/robots.txt",
"es": publicRuntimeConfig.domains.es.protocol + publicRuntimeConfig.domains.es.domain + (publicRuntimeConfig.domains.es.prefix ? publicRuntimeConfig.domains.es.prefix : '') + "/robots.txt",
"fr": publicRuntimeConfig.domains.fr.protocol + publicRuntimeConfig.domains.fr.domain + (publicRuntimeConfig.domains.fr.prefix ? publicRuntimeConfig.domains.fr.prefix : '') + "/robots.txt",
"it": publicRuntimeConfig.domains.it.protocol + publicRuntimeConfig.domains.it.domain + (publicRuntimeConfig.domains.it.prefix ? publicRuntimeConfig.domains.it.prefix : '') + "/robots.txt",
"vn": publicRuntimeConfig.domains.vn.protocol + publicRuntimeConfig.domains.vn.domain + (publicRuntimeConfig.domains.vn.prefix ? publicRuntimeConfig.domains.vn.prefix : '') + "/robots.txt"
                    };
                    return alternates[locale] ?? alternates['en'];
                }
    
                
