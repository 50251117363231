import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './FooterInfo.module.css';
import { ReactComponent as SVGLogoUA } from './assets/mini-logo.svg';

interface FooterInfoProps {
    darkMode?: boolean;
}

const FooterInfo = ({ darkMode = false }: FooterInfoProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };
    return (
        <div className={clsx(styles['mfooter-info'], darkMode ? styles.darkMode : '')}>
            <p className={styles['mfooter-copyright']}>
                <CustomLink
                    href={{ pathname: '/' }}
                    className={styles['mfooter-logo']}
                    title={t('logo_title')}
                >
                    <SVGLogoUA />
                </CustomLink>
                <span
                    className={styles['mfooter-text']}
                    dangerouslySetInnerHTML={{
                        __html: t('copyright_part1') + ' ' + getCurrentYear() + ' ' + t('copyright_part2'),
                    }}
                />
            </p>
        </div>
    );
};

export default FooterInfo;
