import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './FooterLang.module.css';
import { ReactComponent as SVGWorld } from './assets/world.svg';

interface FooterLangProps {
    locales: Array<string>;
    darkMode?: boolean;
    supportedURLs: Array<{
        hrefLang: string;
        href: string;
    }>;
}

export default function FooterLang({ locales, supportedURLs, darkMode = false }: FooterLangProps): JSX.Element {
    const { t } = useTranslation('base/_blocks/footer');
    const router = useRouter();
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        document.body.addEventListener('click', () => {
            setPopupVisible(false);
        });
    });

    interface Languages {
        [key: string]: { name: string; icon: string };
    }

    const localeTranslations: Languages = {
        de: { name: t('language_german'), icon: t('language_code_de') },
        en: { name: t('language_english'), icon: t('language_code_en') },
        es: { name: t('language_spanish'), icon: t('language_code_es') },
        fr: { name: t('language_france'), icon: t('language_code_fr') },
        it: { name: t('language_italian'), icon: t('language_code_it') },
        pl: { name: t('language_polish'), icon: t('language_code_pl') },
        pt: { name: t('language_portuguese'), icon: t('language_code_pt') },
        vi: { name: t('language_vietnamese'), icon: t('language_code_vi') },
        ru: { name: t('language_russian'), icon: t('language_code_ru') },
    };

    const filteredTranslations: Languages = {};

    for (const lang of locales) {
        if (localeTranslations[lang]) {
            filteredTranslations[lang] = localeTranslations[lang];
        }
    }

    const sortedTranslations: Languages = {};

    Object.keys(filteredTranslations)
        .sort((a, b) => filteredTranslations[a].name.localeCompare(filteredTranslations[b].name))
        .forEach((key) => {
            sortedTranslations[key] = filteredTranslations[key];
        });

    let currentLocale = sortedTranslations[router.locale ? router.locale?.replace('vn', 'vi') : 'en'];

    return (
        <div
            className={clsx(styles['mfooter-lang-wrap'], darkMode ? styles.darkMode : '')}
            data-test="languageSwitcher"
        >
            <h3>{t('header_language')}</h3>
            <span
                className={styles['mfooter-lang']}
                title={t('language_label')}
            >
                <span
                    className={clsx(styles['mfooter-lang-change'], popupVisible && styles.visible)}
                    onClick={(e) => {
                        e.stopPropagation();
                        setPopupVisible(!popupVisible);
                    }}
                >
                    {currentLocale && (
                        <>
                            <SVGWorld className={styles['mfooter-world-icon']} />
                            {currentLocale.name}
                        </>
                    )}
                </span>
            </span>
            {popupVisible && (
                <span
                    className={styles['mfooter-popup']}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ul>
                        {Object.keys(sortedTranslations).map((locale) => (
                            <li key={locale}>
                                {locale === router.locale ? (
                                    <span>
                                        <div className={styles['mfooter-lang-icon']}>{sortedTranslations[locale].icon}</div>
                                        {sortedTranslations[locale].name}
                                    </span>
                                ) : (
                                    <a href={supportedURLs.filter((item) => item.hrefLang === locale)[0]?.href}>
                                        <div className={styles['mfooter-lang-icon']}>{sortedTranslations[locale].icon}</div>
                                        {sortedTranslations[locale].name}
                                    </a>
                                )}
                            </li>
                        ))}
                    </ul>
                </span>
            )}
        </div>
    );
}
