import clsx from 'clsx';

import styles from './FooterContainer.module.css';

interface FooterContainerProps {
    darkMode?: boolean;
    children: Array<JSX.Element> | JSX.Element;
    className?: string;
}

const FooterContainer = ({ children, darkMode = false, className }: FooterContainerProps) => {
    return <div className={clsx(styles.container, darkMode ? styles.darkMode : '', className)}>{children}</div>;
};

export default FooterContainer;
