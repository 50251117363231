import React from 'react';

import CustomLink from '@components/global/CustomLink';

import styles from './Button.module.css';

interface ButtonProps {
    variant?: 'text' | 'contained' | 'outlined' | 'solid' | 'solidInverse';
    color?: 'blue' | 'yellow' | 'orange' | 'green' | 'navy';
    label: string | JSX.Element;
    className?: string;
    size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
    href?: string;
    target?: string;
    customLink?: JSX.Element;
    type?: 'button' | 'submit';
    disabled?: boolean;
    rel?: string;
    onClick?: (e: React.MouseEvent) => void;
}

export const Button = ({ variant, href, target, customLink, color = 'blue', label, size = 'large', className, type = 'button', disabled = false, rel = '', ...otherProps }: ButtonProps) => {
    return (
        <>
            {href ? (
                <a
                    {...otherProps}
                    href={href}
                    rel={rel}
                    target={target}
                    className={[styles.btn, className, `${variant ? styles[variant] : ''}`, `${color ? styles[color] : ''}`, size ? styles[size] : ''].filter(Boolean).join(' ')}
                >
                    {label}
                </a>
            ) : customLink ? (
                <CustomLink
                    {...customLink.props}
                    {...otherProps}
                    className={[styles.btn, className, `${variant ? styles[variant] : ''}`, `${color ? styles[color] : ''}`, size ? styles[size] : ''].filter(Boolean).join(' ')}
                >
                    {label}
                </CustomLink>
            ) : (
                <button
                    {...otherProps}
                    type={type}
                    disabled={disabled}
                    className={[styles.btn, className, `${variant ? styles[variant] : ''}`, `${color ? styles[color] : ''}`, size ? styles[size] : ''].filter(Boolean).join(' ')}
                >
                    {label}
                </button>
            )}
        </>
    );
};
