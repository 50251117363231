import getConfig from 'next/config';

import Bowser from 'bowser';

const { publicRuntimeConfig } = getConfig();

export function reportWebVitals(metric) {
    if (publicRuntimeConfig.stage === 'master' || publicRuntimeConfig.stage === 'beta') {
        const screenInfo = () => {
            const ret = {};
            ret.width = window.innerWidth;
            ret.height = window.innerHeight;

            if (typeof window.screen !== 'undefined') {
                if (typeof window.screen.availWidth !== 'undefined') {
                    ret.availWidth = window.screen.availWidth;
                }
                if (typeof window.screen.availHeight !== 'undefined') {
                    ret.availHeight = window.screen.availHeight;
                }
            }
            if (typeof window.devicePixelRatio !== 'undefined') {
                ret.devicePixelRatio = Math.round(window.devicePixelRatio * 100) / 100;
            }

            return ret;
        };

        const clientInfo = typeof window !== 'undefined' ? Bowser.parse(window.navigator.userAgent) : null;
        const additionalInfo = {
            event_type: 'PERFORMANCE',
            responsible: 'dms',
        };

        let metricData = {
            metricName: metric.name,
            metricData: {
                duration: metric.value,
            },
            ...clientInfo,
            ...additionalInfo,
            screen: screenInfo(),
            location: {
                href: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
                fulladdress: window.location.href,
                search: window.location.search,
                hostname: window.location.hostname,
                pathname: window.location.pathname,
            },
        };

        function logMetricData(metricData) {
            const url = 'https://index-log.getresponse.com/index/marketing_performance';
            const body = JSON.stringify(metricData);
            var blob = new Blob([body], { type: 'application/json' });

            try {
                navigator.sendBeacon(url, blob);
            } catch (error) {}
        }

        switch (metric.name) {
            case 'FCP':
                logMetricData(metricData);
                break;
            case 'LCP':
                logMetricData(metricData);
                break;
            case 'CLS':
                logMetricData(metricData);
                break;
            case 'FID':
                logMetricData(metricData);
                break;
            case 'TTFB':
                logMetricData(metricData);
                break;
            case 'Next.js-hydration':
                logMetricData(metricData);
                break;
            case 'Next.js-route-change-to-render':
                logMetricData(metricData);
                break;
            case 'Next.js-render':
                logMetricData(metricData);
                break;
            default:
                break;
        }
    }
}
