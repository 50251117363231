import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteFeaturesWebsiteBuilderTemplates, laravelRouteWebinarLibrary } from '@static_components/laravelLinks';
import { laravelRouteFeaturesSupport, laravelRouteHelp } from '@static_components/laravelLinks';
import clsx from 'clsx';

import styles from './MenuProduct.module.css';

interface FooterMenuResourcesProps {
    darkMode?: boolean;
}

const FooterMenuResources = ({ darkMode = false }: FooterMenuResourcesProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    const { locale } = useRouter();

    return (
        <div className={clsx(styles.block, darkMode ? styles.darkMode : '')}>
            <h3>{t('resources_header')}</h3>
            <ul className={styles['sites']}>
                <li>
                    <a
                        href={laravelRouteHelp(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_help')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteFeaturesSupport(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_support')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: '/resources' }}
                        data-test="footerLink"
                    >
                        {t('menu_resources')}
                    </CustomLink>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: '/customers' }}
                        data-test="footerLink"
                    >
                        {t('menu_customers')}
                    </CustomLink>
                </li>
                <li>
                    <a
                        href="https://podcast.getresponse.com/"
                        data-test="footerLink"
                    >
                        {t('menu_podcast')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteWebinarLibrary(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_webinars')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: '/features/email-marketing/templates' }}
                        data-test="footerLink"
                    >
                        {t('menu_email_templates')}
                    </CustomLink>
                </li>
                <li>
                    <a
                        href={locale === 'fr' ? laravelRouteFeaturesWebsiteBuilderTemplates('en') : laravelRouteFeaturesWebsiteBuilderTemplates(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_website_templates')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: '/esps' }}
                        data-test="footerLink"
                    >
                        {t('menu_esps')}
                    </CustomLink>
                </li>
                <li>
                    <a
                        href={'https://apidocs.getresponse.com/v3'}
                        data-test="footerLink"
                    >
                        {t('menu_api_docs')}
                    </a>
                </li>
                {['en', 'pl'].includes(locale ?? '') && (
                    <li>
                        <CustomLink
                            href={{ pathname: '/move-to-getresponse' }}
                            data-test="footerLink"
                        >
                            {t('menu_move_to_getresponse')}
                        </CustomLink>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default FooterMenuResources;
